import React, { FC } from "react"
import Jumbotron from "src/components/Jumbotron"
import { Layout } from "src/components/Layout"
import Link from "src/utils/Link"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"

const LegalSubPage: FC<Props> = ({
  back,
  header,
  html,
  subheader,
  metaTitle,
  metaDescription,
  date,
}) => {
  const { currentPath } = usePages()

  return (
    <Layout
      title={metaTitle}
      description={metaDescription}
      openGraph={{
        title: metaTitle,
        description: metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      headerClassname={`white-nav`}
    >
      <Jumbotron
        title={header}
        subtitle={subheader}
        className={styles.header}
        topContent={
          <Link className={`button button--light ${styles.backTo}`} to="/legal">
            {back}
          </Link>
        }
      >
        <span className={styles.date}>{date}</span>
      </Jumbotron>
      <div className={`content-wrapper ${styles.root}`}>
        <div
          className={styles.markdownStyles}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

export default LegalSubPage
