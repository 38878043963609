import { graphql } from "gatsby"
import React, { FC } from "react"
import LegalSubPage from "src/components/LegalSubPage"
import { Props } from "./types"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
    markdownRemark: {
      frontmatter: { title },
      html,
    },
  },
}) => <LegalSubPage html={html} title={title} {...texts} />

export const pageQuery = graphql`
  query($ymlPath: String!, $mdPath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $mdPath }) {
      html
      frontmatter {
        title
      }
    }

    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        legalTitle
        back
        date
      }
    }
  }
`

export default Template
